import Cookies from 'js-cookie';
import {
	CLICK_START,
	REGISTER_BORROWER,
	CREATE_USER,
	LOGIN_USER,
	LOGOUT_USER,
	SUBMIT_APPLICATION,
	UPDATE_APPLICATION
} from '../actions/user';

const userStates = {
	"abc": {
		"REGISTER_USER": 'returning' 
	},
	"new": {
		"SUBMIT_APPLICATION": "submitted"
	},
	"submitted": {
		
	}
}

const userStates2 = {
	"start": {
		"CLICK_START": "register",
	},
	"register": {
		"REGISTER_BORROWER": "link"
	},
	"link": {
		"SUBMIT_APPLICATION": "submit"
	}
}

export default function userReducer(state = {}, action) {
	switch(action.type) {
		case LOGIN_USER:
			return {
				...state,
				user: {
					...action.payload.user.user,
					status: 'start'
				}
			};
		case LOGOUT_USER:
			localStorage.removeItem('lendingState');
			Cookies.remove('token');
			return {};
		case CLICK_START:
			return {
				user: {
					...state.user,
					status: userStates2[state.user.status][action.type]
				}
			}
		case REGISTER_BORROWER:
			return {
				user: {
					...state.user,
					status: userStates2[state.user.status][action.type]
				}
			}
		case SUBMIT_APPLICATION:
			return {
				user: {
					...state.user,
					status: userStates2[state.user.status][action.type]
				}
			}
		case UPDATE_APPLICATION:
			return {
				...state,
				application: action.payload
			}
		default:
			return state;
	}
}