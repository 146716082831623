import React, { useState, useEffect, useRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Row, Col, Skeleton, Button, Radio, Modal, Result, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import ReactJson from 'react-json-view';
import axios from '../lib/axios';

import Application from '../components/Application';

const URL = 'ws://5bf78faa.ngrok.io';

const Snapshot = (props) => {

	const [loading, setLoading] = useState(true);
	const [btnLoading, setBtnLoading] = useState(false);
	const [application, setApplication] = useState(null);
	const [applicationStatus, setApplicationStatus] = useState(null);
	const [snapshot, setSnapshot] = useState({});
	const [radio, setRadio] = useState(0);
	const [modalVisible, setModalVisible] = useState(false);
	const ws = useRef(null);

	const getApplication = async () => {
		let response, data;
		try {
			response = await axios.get(`/application?uid=${props.match.params.borrowerId}`, { withCredentials: true });
			({ data } = response);
			setApplication(data);
			setApplicationStatus(data.status);
		} catch (e) {
			if (e.response) response = e.response;
			if (response) ({ data } = response);
		}
		setLoading(false);
		return data;
	}

	const getSnapshot = async (applicationId, pdf = false) => {
		let url = `/snapshot/${applicationId}?uid=${props.match.params.borrowerId}`;
		if (pdf) url = `/snapshot/${applicationId}?uid=${props.match.params.borrowerId}&format=pdf`;
		let response, data;
		try {
			response = await axios.get(url, { withCredentials: true });
			({ data } = response);
		} catch (e) {
			if (e.response) response = e.response;
			if (response) ({ data } = response);
		}
		return data;
	}

	useEffect(() => {
		async function fetchData() {
			const application = await getApplication();
			const { id, status } = application;
			//setApplication(application);
			//setApplicationStatus(status);
			//setLoading(false);
			if (status === 'COMPLETED' || status === 'ERROR') return;
			ws.current = new WebSocket(URL);
			ws.current.onopen = () => {
				console.log('client connection opened');
				ws.current.send(JSON.stringify({ applicationId: id }));
				ws.current.onmessage = e => {
					console.log('client received messaged');
					const { data } = e;
					console.log(data);
					if (data === 'COMPLETED' || data === 'ERROR') ws.current.close();
					setApplicationStatus(data);
				}
			}
			ws.current.onclose = () => console.log('client closed connection');
			return () => {
				ws.current.close();
			}
		}
		fetchData();
	}, [setApplication, setApplicationStatus, setLoading]);

	const onChange = async ({ target }) => {
		const { value } = target;
		setRadio(value);
	}

	const viewSnapshot = async (applicationId) => {
		console.log(radio);
		switch (radio) {
			case 1:
				const snapshotPdf = await getSnapshot(applicationId, true);
				await downloadPdf(snapshotPdf);
				break;
			case 2:
				const snapshot = await getSnapshot(applicationId);
				await setSnapshot(snapshot);
				await setModalVisible(true);
				break;
			default:
				break;
		}
		setBtnLoading(false);
	}

	const downloadPdf = async (snapshot) => {
		const linkSource = `data:application/pdf;base64,${snapshot.docContent}`;
		const fileName = `snapshot-${snapshot.application.id}.pdf`;
		let downloadLink = document.createElement('a');
		downloadLink.href = linkSource;
		downloadLink.download = fileName;
		await downloadLink.click();
	}

	return (
		<React.Fragment>
			<Row type="flex" justify="center">
				<Col xs={18} sm={18} md={18} lg={18} xl={18}>
					{ loading &&
						
							<Skeleton
								title={{ width: '15%' }}
								paragraph={{ rows: 10, width: ['100%', '100%', '100%', '15%', '100%', '100%', '100%', '100%', '100%', '100%'] }}
								active
							/>
					}
					{ application &&
							<React.Fragment>
								<Modal
									title={`Snapshot JSON - ${application.id}`}
									visible={modalVisible}
									width='75%'
									onCancel={() => setModalVisible(false)}
									maskClosable={true}
									footer={null}
								>
									<ReactJson src={snapshot} collapsed={true} />
								</Modal>
								<Row>
									<Col span="24">
										<h1>Application Details</h1>
									</Col>
								</Row>
								<Row>
									<Col span="12">
										<p>Application ID: {application.id}</p>
									</Col>
									<Col span="12">
										{
											applicationStatus === 'COMPLETED' ? (
												<p style={{ float: 'right' }}>
													<span>Snapshot: </span>
													<Radio.Group onChange={onChange} value={radio}>
														<Radio value={1}>PDF</Radio>
														<Radio value={2}>JSON</Radio>
													</Radio.Group>
													<Button type="primary" size="small" loading={btnLoading} onClick={() => {
														setBtnLoading(true);
														viewSnapshot(application.id);
													}}>
														View
													</Button>
												</p>
											) : (
												<p style={{ float: 'right' }}>
													<Spin indicator={
														<LoadingOutlined style={{ fontSize: 16 }} spin />
													} /> Processing Snapshot
												</p>
											)
										}
									</Col>
								</Row>
								<Application data={application} view="lender" />
							</React.Fragment>
					}
					{
						(!loading && !application) &&
						<Result
							status="warning"
							title="No Application Submitted"
							extra={
								<React.Fragment>
									<p>
										Borrower must submit an application before Lender can view the Financial Snapshot.
										<br />Please submit an application as a Borrower.
									</p> 
									<Button type="primary" size="large">
										<Link to="/">
											Go to Borrower View
										</Link>
									</Button>
								</React.Fragment>
							}
						/>
					}
				</Col>
			</Row>
		</React.Fragment>
	);
}

export default withRouter(Snapshot);