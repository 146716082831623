import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Result, Row, Col, Skeleton } from 'antd';
import axios from '../../lib/axios';
import Application from '../Application';
import { updateApplication } from '../../actions/user';

const Complete = () => {
	const [loading, setLoading] = useState(true);
	const [application, setApplication] = useState({});
	const dispatch = useDispatch();

	const getApplication = async () => {
		let response, data, message;
		try {
			response = await axios.get('/application', { withCredentials: true });
			({ data } = response);
			dispatch(updateApplication(data));
			setApplication(data);
		} catch (e) {
			if (e.response) response = e.response;
			if (response) ({ data } = response);
			if (data) ({ message } = data);
		}
		setLoading(false);
	}

	useEffect(() => {
		getApplication();
	}, []);

	return (
		<React.Fragment>
			<Row type="flex" justify="center" style={{ minHeight: '750px' }}>
				<Col xs={14} sm={14} md={14} lg={14} xl={14}>
					{ loading ?
						(
							<Skeleton
								title={{ width: '15%' }}
								paragraph={{
									rows: 10,
									width: [
										'100%', '100%', '100%', '15%', '100%', '100%', '100%', '100%', '100%', '100%'
									]
								}}
								active
							/>
						) : (
							<React.Fragment>
								<Result
									status="success"
									title={`Success! Your Financial Snapshot has been submitted`}
									subTitle={
										<React.Fragment>
											<h3>Reference #: {application.id}</h3>
											<p>You will be contacted via e-mail once we complete review</p>
										</React.Fragment>
									}
								/>
								<Application data={application} view="borrower" />
							</React.Fragment>
						)
					}
				</Col>
			</Row>
		</React.Fragment>
	);
}

export default Complete;