import React from 'react';
import { Table } from 'antd';

const columns = {
	borrower: [
		{
			title: 'Name',
			dataIndex: 'accountName',
			width: '25%',
			render: text => <span>{ text ? text : 'N/A' }</span>
		},
		{
			title: 'Type',
			dataIndex: 'accountType',
			width: '25%',
			render: text => <span style={{ textTransform: 'capitalize' }}>{ text ? text.toLowerCase() : 'N/A' }</span>
		},
		{
			title: 'Number',
			dataIndex: 'accountNumber',
			width: '25%',
			render: text => <span>{ text ? text : 'N/A' }</span>
		},
		{
			title: 'Balance',
			dataIndex: 'balance',
			width: '25%',
			render: balance => <span>{ (balance && balance.amount) ? `$${balance.amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}` : 'N/A' }</span>
		}
	],
	lender: [
		{
			title: 'Name',
			dataIndex: 'accountName',
			width: '25%',
			render: accountName => <span>{ accountName ? accountName : 'N/A' }</span>
		},
		{
			title: 'Type',
			dataIndex: 'accountType',
			width: '25%',
			render: accountType => <span style={{ textTransform: 'capitalize' }}>{ accountType ? accountType.toLowerCase() : 'N/A' }</span>
		},
		{
			title: 'Number',
			dataIndex: 'accountNumber',
			width: '25%',
			render: accountNumber => <span>{ accountNumber ? accountNumber : 'N/A' }</span>
		},
		{
			title: 'Balance',
			dataIndex: 'balance',
			width: '25%',
			render: balance => <span>{ (balance && balance.amount) ? `$${ balance.amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}` : 'N/A' }</span>
		}
	]
};

const AccountTable = ({ dataSource, view }) => {
	return <Table columns={columns[view]} dataSource={dataSource} pagination={false} />
}

export default AccountTable;