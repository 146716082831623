import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { logoutUser } from '../actions/user';

import logo from '../images/yodlee-logo-reverse.svg';

const { Header } = Layout;
const { SubMenu } = Menu;

const selectionMap = {
	'/': '1',
	'/lender': '2',
	'/documentation': '3'
}

const UserMenu = ({ props }) => {
	const dispatch = useDispatch();
	const { user } = useSelector(state => state);
	return (
		<Menu theme="dark" mode="horizontal" selectedKeys={[ selectionMap[props.location.pathname] || '2' ]}>
			<Menu.Item key="1">
				<Link to="/">Borrower View</Link>
			</Menu.Item>
			<Menu.Item key="2">
				<Link to="/lender">Lender View</Link>
			</Menu.Item>
			<Menu.Item key="3">
				<Link to="/documentation">Documentation</Link>
			</Menu.Item>
			<SubMenu
				style={{ float: 'right' }}
				title={
					<span className="submenu-title-wrapper">
						<UserOutlined /> { user.cobrandName }
					</span>
				}
			>
				<Menu.ItemGroup title={ 
					<span className="menu-item-title">
						ID: <i>{ user.accessId }</i>
					</span> 
				}>
					<Menu.Item key="4" onClick={() => {
						dispatch(logoutUser());
						props.history.push('/');
					}}>
						<LogoutOutlined /> Logout
					</Menu.Item>
				</Menu.ItemGroup>
			</SubMenu>
		</Menu>
	)
}

const GuestMenu = () => {
	return (
		<Menu theme="dark" mode="horizontal" />
	);
}

const Navbar = (props) => {
	const { user } = useSelector(state => state);
	return (
		<Header>
			<div className="logo">
				<Link to="/">
					<img src={logo} />
				</Link>
			</div>
			{ user ? <UserMenu props={props} /> : <GuestMenu /> }
		</Header>
	);
}

export default withRouter(Navbar);