import React from 'react';
import { Table } from 'antd';
import { LinkOutlined, FileWordOutlined, FileZipOutlined, FilePdfOutlined } from '@ant-design/icons';

const fontSize = 16;

const components = {
	link: <LinkOutlined style={{ fontSize }} />,
	word: <FileWordOutlined style={{ fontSize }} />,
	zip: <FileZipOutlined style={{ fontSize }} />,
	pdf: <FilePdfOutlined style={{ fontSize }} />
}

const columns = [
	{
		title: ' ',
		dataIndex: 'icon',
		key: 'icon',
		width: '4%',
		render: icon => <span>{ components[icon] }</span>
	},
	{
		title: 'Name',
		dataIndex: 'name',
		key: 'name',
		width: '22%'
	},
	{
		title: 'Description',
		dataIndex: 'description',
		key: 'description',
		width: '50%'
	},
	{
		title: 'Updated',
		dataIndex: 'updated',
		key: 'updated',
		width: '14%'
	},
	{
		title: 'Action',
		dataIndex: 'action',
		key: 'action',
		width: '10%',
		render: (text, record) => <a href={record.href} target="_blank">{text}</a>
	}
]

const dataSource = [
	{
		icon: 'link',
		name: 'Swagger',
		description: 'Specification which describes the Snapshot RESTful APIs. Username: yodSandbox, Password: SwordF1sh',
		updated: 'May 12, 2020',
		action: 'View',
		href: 'https://snservicesstage.yodlee.com/swagger-ui.html'
	},
	{
		icon: 'link',
		name: 'Postman Collection',
		description: 'Collection which can be used to test Snapshot API\'s functionality',
		updated: 'June 1, 2020',
		action: 'Download',
		href: '/documents/Yodlee Snapshot API.postman_collection'
	},
	{
		icon: 'link',
		name: 'Postman Environment',
		description: 'Environment template which can be used with the Postman Collection',
		updated: 'June 1, 2020',
		action: 'Download',
		href: '/documents/Yodlee Snapshot Environment.postman_environment'
	},
	{
		icon: 'pdf',
		name: 'Integration Guide',
		description: 'Technical document outlining best practices for integrating Snapshot solution',
		updated: 'June 1, 2020',
		action: 'View',
		href: '/documents/Envestnet Yodlee Snapshot Integration Guide_V1_1.pdf'
	},
	{
		icon: 'zip',
		name: 'Sample App',
		description: 'Complete source code for Borrower and Lender Apps, built using NodeJS and ReactJS',
		updated: 'May 12, 2020',
		action: 'Download',
		href: '#'
	},
	{
		icon: 'zip',
		name: 'RSA Keygen Utility',
		description: 'JAR file which will generate a private/public key pair for JWT authentication',
		updated: 'May 12, 2020',
		action: 'Download',
		href: '/documents/rsa-keygen.zip'
	}
]

const DocumentTable = () => {
	return <Table columns={columns} dataSource={dataSource} pagination={false} /> 
}

export default DocumentTable;