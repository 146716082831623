import Cookies from 'js-cookie';
import { verify } from 'jsonwebtoken';

const appPublic = '-----BEGIN PUBLIC KEY-----\nMIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAz58SHJUslnQjfG7qeHYO2ZhRAKo66HO9UKo+5Tu8yW3D5ZJUCFGjn7AzdolrOcD6UTiVuFZmpb6UdTHMvM0nvEM4dDcySu7Ra/OK0jx6zNs4lri8w6kShodtvP/zXeYFKrY6rBeEPQnFNGa+Z4JBiTDOjWeiyopeM90A5ZKwYxr/UYc67yKJ2bkYp1deYhUZkvyLt4sjtZUPQ/KAlAxkWMlDx4A4Jo1gkc8Ues5TDmLs1ZD0iePRxKhgbG8CZm/WCBoelH6lesEvGlsapQbdb1LV7m6LWQP2hkBpgnzEs4DVuBFdFX8g4K3Xw+jUqdvzddJEH2PEuxcm4WLjFoi46Qkmu3qk5aHML3My+j8s43ihO2xWKFiiIJOsoyFu7EnRnkFkxwJxeHS/aIGKKI1uLvqwMnpY0nvB/KWs4IFBJPJ4V+Z8bJfRWQmbrHbaCAH641B/LBLEfww3RVmPC+pi36KyQ18sapPvaNebOq25xzimFveh70CWhDaK590FvRe/WuX3tD9x3/st81TeuKCld4874qgQSVALVR7pjBeS7CYmwsZY7dMRLvtC/JpHgCIMLIlfoKbuS2PVU2lD6ltQT0wsUo5fHH75RqZO9xWUlFt5aIqQjymuFLsYJB3Bp3DSFkSO30cPVdXhHGuFuK775QWXx+7nHr0jnLZgOolDyycCAwEAAQ==\n-----END PUBLIC KEY-----';

const isAuth = () => {
	try {
		const token = Cookies.get('token');
		verify(token, appPublic);
		return true;
	} catch (e) {
		return false;
	}
}

export default isAuth;