import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Row, Col, Form, Input, Button, Alert, Tooltip } from 'antd';
import axios from '../../lib/axios';
import { registerBorrower } from '../../actions/user';

const layout = {
	labelCol: {
		span: 8,
	},
	wrapperCol: {
		span: 8,
	},
};

const tailLayout = {
	wrapperCol: {
		offset: 8,
		span: 8,
	},
};

const Register = (props) => {

	const [loading, setIsLoading] = useState(false);
	const [error, setError] = useState(false);
	const [message, setMessage] = useState('');
	const dispatch = useDispatch();

	const handleRegister = async (values) => {
		let response, data, message;
		try {
			response = await axios.post('/borrower', values, { withCredentials: true });
			({ data } = response);
			setError(false);
			dispatch(registerBorrower());
			props.history.push('#link');
		} catch (e) {
			if (e.response) response = e.response;
			if (response) ({ data } = response);
			if (data) ({ message } = data);
			console.log(JSON.stringify(e));
			setError(true);
			setMessage(message);
		}
		setIsLoading(false);
	}

	const onFinishFailed = errorInfo => {
		setIsLoading(false);
		console.log('Failed:', errorInfo);
	};

	return (
		<React.Fragment>
			<Row type="flex" justify="center">
				<Col span="8">
					<h2>Borrower Registration</h2>
					<div>Please enter your details below in order to continue to the next step.</div>
				</Col>
			</Row>
			{
				error &&
				<Row type="flex" justify="center">
					<Col span="8">
						<Alert message={message || 'Server error'} type="error" showIcon />
					</Col>
				</Row>
			}
			<div style={{ marginTop: '15px' }}>
				<Form
					{...layout}
					name="basic"
					size="large"
					onFinish={handleRegister}
					onFinishFailed={onFinishFailed}
				>

					<Form.Item
						label="Borrower ID"
						name="loginName"
						rules={[
							{
								required: true,
								message: 'Please enter your ID',
							},
						]}
					>
						<Input />
					</Form.Item>					

					<Form.Item
						label="First Name"
						name="firstName"
						rules={[
							{
								required: true,
								message: 'Please enter your first name',
							},
						]}
					>
						<Input />
					</Form.Item>

					<Form.Item
						label="Last Name"
						name="lastName"
						rules={[
							{
								required: true,
								message: 'Please enter your last name',
							},
						]}
					>
						<Input />
					</Form.Item>

					<Form.Item {...tailLayout}>
						<Button type="primary" htmlType="submit" loading={loading} onClick={() => setIsLoading(true)}>
							Continue
						</Button>
					</Form.Item>
				</Form>
			</div>
		</React.Fragment>
	);
}

export default withRouter(Register);