import React from 'react';
import { Switch, Route } from 'react-router-dom';

import isAuth from './middleware/isAuth';

import Home from './pages/Home';
import FastLink from './pages/FastLink';
import Lender from './pages/Lender';
import Snapshot from './pages/Snapshot';
import Register from './components/home/Register';
import Documentation from './pages/Documentation';

const Routes = () => {
	return (
		<Switch>
			<ProtectedRoute exact path="/lender" component={Lender} />
			<ProtectedRoute exact path="/lender/:borrowerId" component={Snapshot} />
			<ProtectedRoute path="/start" component={Register} />
			<ProtectedRoute path="/link" component={FastLink} />
			<ProtectedRoute path="/documentation" component={Documentation} />
			<Route path="/" component={Home} />
		</Switch>
	);
}

const ProtectedRoute = ({ component: Component, ...rest }) => {
	return (
		<Route
			{...rest}
			render={props =>
				isAuth() ? ( <Component {...props} /> ) : ( <Home /> )
			}
		/>
	)
}

export default Routes;