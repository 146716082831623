import React from 'react';
import { Table } from 'antd';

const columns = {
	borrower: [
		{
			title: 'First Name',
			dataIndex: 'name',
			key: 'name',
			width: '33%',
			render: name => <span>{ name.first }</span>
		},
		{
			title: 'Last Name',
			dataIndex: 'name',
			key: 'name',
			width: '33%',
			render: name => <span>{ name.last }</span>
		}
	],
	lender: [
		{
			title: 'Login Name',
			dataIndex: 'loginName',
			key: 'loginName',
			width: '33%',
			render: loginName => <span>{ loginName }</span>
		},
		{
			title: 'First Name',
			dataIndex: 'name',
			key: 'name',
			width: '33%',
			render: name => <span>{ name.first }</span>
		},
		{
			title: 'Last Name',
			dataIndex: 'name',
			key: 'name',
			width: '33%',
			render: name => <span>{ name.last }</span>
		}
	],
};

const ApplicantTable = ({ dataSource, view }) => {
	return <Table columns={columns[view]} dataSource={dataSource} pagination={false} />
}

export default ApplicantTable;