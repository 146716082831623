import React, { useState } from 'react';
import { Row, Col, Form, Input, Button, Alert } from 'antd';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from '../lib/axios';
import { loginUser } from '../actions/user';

const layout = {
	labelCol: {
		span: 8,
	},
	wrapperCol: {
		span: 8,
	}
};

const tailLayout = {
	wrapperCol: {
		offset: 8,
		span: 8,
	}
};

const Login = () => {

	const [loading, setIsLoading] = useState(false);
	const [error, setError] = useState(false);
	const [message, setMessage] = useState('');
	const dispatch = useDispatch();

	const handleLogin = async (values) => {
		let response, data, message;
		try {
			response = await axios.post('/auth/login', values);
			({ data } = response);
			setError(false);
			setIsLoading(false);
			dispatch(loginUser(data));
		} catch (e) {
			if (e.response) response = e.response;
			if (response) ({ data } = response);
			if (data) ({ message } = data);
			setError(true);
			setMessage(message);
			setIsLoading(false);
		}
	}

	const onFinishFailed = errorInfo => {
		setIsLoading(false);
		console.log('Failed:', errorInfo);
	}

	return (
		<React.Fragment>
			<Row type="flex" justify="center">
				<Col span="8">
					<h2>Log in</h2>
				</Col>
			</Row>
			{
				error && <Row type="flex" justify="center">
					<Col span="8">
						<Alert message={message || 'Server error'} type="error" showIcon />
					</Col>
				</Row>
			}
			<div style={{ marginTop: '15px' }}>
				<Form
					{...layout}
					name="basic"
					size="large"
					onFinish={handleLogin}
					onFinishFailed={onFinishFailed}
				>

					<Form.Item
						label="Access ID"
						name="loginName"
						rules={[{ required: true, message: 'Please provide your Access ID' }]}
					>
						<Input />
					</Form.Item>

					<Form.Item
						label="Password"
						name="password"
						rules={[{ required: true, message: 'Please enter your password' }]}
					>
						<Input.Password />
					</Form.Item>

					<Form.Item {...tailLayout}>
						<Button type="primary" htmlType="submit" loading={loading} onClick={() => setIsLoading(true)}>
							Log in
						</Button>
						{/*<Button type="link" htmlType="button">
							<Link to="/register">
								or Register
							</Link>
						</Button>*/}
					</Form.Item>
				</Form>
			</div>
		</React.Fragment>
	);
}

export default Login;