import React from 'react';
import { Layout } from 'antd';
import { BrowserRouter as Router } from 'react-router-dom';

import Navbar from './components/Navbar';
import Routes from './Routes';

import 'antd/dist/antd.css';
import './App.css';

const { Content, Footer } = Layout;

const App = () => {
	return (
		<Router>
			<Layout className="layout" style={{ minHeight: '100vh' }}>
				<Navbar />
				<Content style={{ padding: '0 50px' }}>
					<div className="site-layout-content">
						<Routes />
					</div>
				</Content>
				<Footer style={{ textAlign: 'center' }}>
					&copy;{ new Date().getFullYear() } Yodlee, Inc.
				</Footer>
			</Layout>
		</Router>
	);
}

export default App;