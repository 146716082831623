import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import App from './App';
import * as serviceWorker from './serviceWorker';
import userReducer from './reducers/userReducer';
import userMiddleware from './middleware/user';

const initialState = {
	user: null
};

const persistedState = localStorage.getItem('lendingState') ? JSON.parse(localStorage.getItem('lendingState')) : initialState;

const store = createStore(userReducer, persistedState, applyMiddleware(userMiddleware));

store.subscribe(() => {
	let storeData = store.getState();
    if (storeData.user && storeData.user.status === 'start') {
		let newStoreData = initialState;
		newStoreData.user = storeData.user;
		localStorage.setItem('lendingState', JSON.stringify(newStoreData));
	}
});

ReactDOM.render(
    <React.Fragment>
        <Provider store={store}>
            <App />
        </Provider>
    </React.Fragment>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
