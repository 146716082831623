import axios from '../lib/axios';
import {
	SUBMIT_APPLICATION
} from '../actions/user';

const updateStatus = async () => {
	let response, data;
	try {
		response = await axios.put('/user', { withCredentials: true });
		({ data } = response);
		console.log(response);
	} catch (e) {
		console.log(e);
	}
}

const userMiddleware = (store) => (next) => (action) => {
	switch(action.type) {
		case SUBMIT_APPLICATION:
			updateStatus();
			next(action);
			break;
		default:
			next(action);
	}
}

export default userMiddleware;