export const CREATE_USER = 'CREATE_USER';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const SUBMIT_APPLICATION = 'SUBMIT_APPLICATION';
export const UPDATE_APPLICATION = 'UPDATE_APPLICATION';

export const CLICK_START = 'CLICK_START';
export const REGISTER_BORROWER = 'REGISTER_BORROWER';

export const clickStart = () => ({
	type: CLICK_START
});

export const registerBorrower = () => ({
	type: REGISTER_BORROWER
});

export const createUser = user => ({
	type: CREATE_USER,
	payload: { user }
});

export const loginUser = user => ({
	type: LOGIN_USER,
	payload: { user }
});

export const logoutUser = () => ({
	type: LOGOUT_USER
});

export const submitApplication = () => ({
	type: SUBMIT_APPLICATION
});

export const updateApplication = application => ({
	type: UPDATE_APPLICATION,
	payload: application
});