import React from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Result, Button } from 'antd';
import { Link } from 'react-router-dom';

import { clickStart } from '../../actions/user';
import logo from '../../images/yodlee-logo.png';

const Start = () => {
	const dispatch = useDispatch();
	return (
		<React.Fragment>
			<Row type="flex" justify="center">
				<Col xs={12} sm={12} md={12} lg={12} xl={12}>
					<Result
						icon={<img src={logo} height="125px" />}
						title={<span>Hello,</span>}
						subTitle={
							<React.Fragment>
								<h3 className="secondary">Welcome to Snapshot 2.0 Developer Portal</h3>
								<p>You can start a new loan application by linking all your financial accounts and submitting a request for a Financial Snapshot Report. Once you're ready to get started we'll guide you through the process step by step.</p>
								<Button type="primary" size="large">
									<Link to="#start" onClick={() => dispatch(clickStart())}>
										Get Started
									</Link>
								</Button>
							</React.Fragment>
						}
					/>
				</Col>
			</Row>
		</React.Fragment>
	);
}

export default Start;