import React from 'react';
import { Table } from 'antd';

const LenderTable = ({ props, dataSource }) => {
	const columns = [
		{
			title: 'Login Name',
			dataIndex: 'loginName',
			width: '33%',
			render: (loginName, record) =>
				<span>
					{ loginName ? <a onClick={() => { props.history.push(`/lender/${record._id}`) }}>{ loginName }</a> : 'N/A' }
				</span>
		},
		{
			title: 'First Name',
			dataIndex: 'firstName',
			width: '33%',
			render: firstName => <span>{ firstName ? firstName : 'N/A' }</span>
		},
		{
			title: 'Last Name',
			dataIndex: 'lastName',
			width: '33%',
			render: lastName => <span>{ lastName ? lastName : 'N/A' }</span>
		}
	];
	return <Table columns={columns} dataSource={dataSource} pagination={{ defaultPageSize: 20 }} />
}

export default LenderTable;