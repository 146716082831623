import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Row, Col, Tabs, Button } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import axios from '../lib/axios';
import { submitApplication } from '../actions/user';

const { TabPane } = Tabs;

const FASTLINK_URL = 'https://finapp.yodleevoastage.yodlee.com/authenticate/yodleevoa/';

const FastLink = (props) => {

	const formRef = useRef();
	const [params, setParams] = useState({});
	const [fastlink, setHeight] = useState({ height: 650 });
	const dispatch = useDispatch();

	const getJWT = async () => {
		let response, data, message;
		try {
			response = await axios.put('/borrower/renew', { withCredentials: true });
			({ data } = response);
			let params = {
				app: 10003600,
				jwtToken: `Bearer ${data.yodleeToken}`,
				redirectReq: true,
				extraParams: `iframeResize=true&locationurl=${window.location.href}`
			}
			setParams(params);
		} catch (e) {
			if (e.response) response = e.response;
			if (response) ({ data } = response);
			if (data) ({ message } = data);
		}
		formRef.current.submit();
	}

	useEffect(() => {
		getJWT();
	}, []);

	const handler = useCallback(({ data }) => {
		let { fnToCall, height, action, application } = data;
		if (fnToCall === "newThemeResizeFloater") {
			setHeight({ height });
		}
		if (action === "exit") {
			dispatch(submitApplication());
			props.history.push('#submit');
		}
	}, [setHeight]);

	useEventListener('message', handler);

	return (
		<Row>
			<Col span={18}>
				<form ref={formRef} method="POST" action={FASTLINK_URL} target="fastlink-iframe">
					{
						Object.keys(params).map((name, index) => (
							<input
								key={index}
								type="hidden"
								name={name}
								value={params[name]}
							></input>
						))
					}
				</form>
				<iframe style={{ height: fastlink.height }} name="fastlink-iframe" title="Snapshot FastLink" frameBorder="0" scrolling="no"></iframe>
			</Col>
			<Col span={6}>
				<Tabs type="card" defaultActiveKey="1">
					<TabPane tab="IQ Bank" key="1">
						<p>
							<h3>Institution Credentials</h3>
							<div><b>Username</b>: snapshot_266600</div>
							<div><b>Password</b>: snapshot@iqb</div>
						</p>
					</TabPane>
					<TabPane tab="Dag Site" key="2">
						<p>
							<h3>Institution Credentials</h3>
							<div><b>Username</b>: snapshot_demo.site16441.2</div>
							<div><b>Password</b>: site16441.2</div>
						</p>
					</TabPane>
				</Tabs>
			</Col>
		</Row>
	);
}

const useEventListener = (eventName, handler, element = window) => {
	const savedHandler = useRef();

	useEffect(() => {
		savedHandler.current = handler;
	}, [handler]);

	useEffect(() => {
		const isSupported = element && element.addEventListener;
		if (!isSupported) return;

		const eventListener = event => {
			console.log(event.data);
			savedHandler.current(event)
		};

		element.addEventListener(eventName, eventListener);

		return () => {
			element.removeEventListener(eventName, eventListener);
		};
	}, [eventName, element]);
}

export default withRouter(FastLink);