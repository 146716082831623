import React from 'react';
import { Table } from 'antd';

const columns = {
	lender: [
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status'
		},
		{
			title: 'Submitted On',
			dataIndex: 'submissionDate',
			key: 'submissionDate'
		},
		{
			title: 'Days',
			dataIndex: 'days',
			key: 'days'
		},
		{
			title: 'Number of Accounts',
			dataIndex: 'accounts',
			key: 'accounts',
			render: accounts => <span>{accounts.length}</span>
		}
	]
}

const ApplicationTable = ({ dataSource, view }) => {
	return <Table columns={columns[view]} dataSource={dataSource} pagination={false} />
}

export default ApplicationTable;