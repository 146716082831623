import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import isAuth from '../middleware/isAuth';
import { logoutUser } from '../actions/user';

import Login from '../components/Login';
import Start from '../components/home/Start';
import Register from '../components/home/Register';
import FastLink from '../pages/FastLink';
import Resume from '../components/home/Resume';
import Complete from '../components/home/Complete';

const components = {
	guest: <Login />,
	start: <Start />,
	register: <Register />,
	link: <FastLink />,
	submit: <Complete />
}

const Home = () => {
	const dispatch = useDispatch();
	if (!isAuth()) dispatch(logoutUser());
	const user = useSelector(state => state.user);
	if (user) {
		if (user.status) return components[user.status]; else return components.start; 
	} else {
		return components.guest;
	}
}

export default Home;