import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Row, Col, Skeleton, Button, Result, Table } from 'antd';
import axios from '../lib/axios';

import LenderTable from '../components/tables/LenderTable';

const Lender = (props) => {
	const [loading, setLoading] = useState(true);
	const [application, setApplication] = useState(null);

	const getApplication = async () => {
		let response, data;
		try {
			response = await axios.get('/borrower/all', { withCredentials: true });
			({ data } = response);
			setApplication(data);
		} catch (e) {
			if (e.response) response = e.response;
			if (response) ({ data } = response);
		}
		setLoading(false);
		return data;
	}

	useEffect(() => {
		async function fetchData() {
			await getApplication();
		}
		fetchData();
	}, [setApplication, setLoading]);

	return (
		<React.Fragment>
			<Row type="flex" justify="center">
				<Col xs={18} sm={18} md={18} lg={18} xl={18}>
					{ loading &&
						
							<Skeleton
								title={{ width: '15%' }}
								paragraph={{ rows: 10, width: ['100%', '100%', '100%', '15%', '100%', '100%', '100%', '100%', '100%', '100%'] }}
								active
							/>
					}
					{ application &&
							<React.Fragment>
								<h1>Borrowers</h1>
								<div style={{ marginBottom: '10px' }}>List of borrowers which are registered in the system. Clicking on a borrower will bring you to their latest Snapshot application.</div>
								<LenderTable props={props} dataSource={application} />
							</React.Fragment>
					}
					{
						(!loading && !application) &&
						<Result
							status="warning"
							title="No Application Submitted"
							extra={
								<React.Fragment>
									<p>
										Borrower must submit an application before the Lender can view the Financial Snapshot.
										<br />Please return to the Borrower View and submit an application.
									</p> 
									<Button type="primary" size="large">
										<Link to="/">
											Go to Borrower View
										</Link>
									</Button>
								</React.Fragment>
							}
						/>
					}
				</Col>
			</Row>
		</React.Fragment>
	);
}

export default withRouter(Lender);