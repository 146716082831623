import React from 'react';
import { Table } from 'antd';

const columns = {
	lender: [
		{
			title: 'Low Balance 1',
			dataIndex: 'lowBalAmt1',
			key: 'lowBalAmt1',
			render: lowBalAmt1 => <span>{ lowBalAmt1 ? `$${ lowBalAmt1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}` : 'N/A' }</span>
		},
		{
			title: 'Low Balance 2',
			dataIndex: 'lowBalAmt2',
			key: 'lowBalAmt2',
			render: lowBalAmt2 => <span>{ lowBalAmt2 ? `$${ lowBalAmt2.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}` : 'N/A' }</span>
		},
		{
			title: 'Low Balance 3',
			dataIndex: 'lowBalAmt3',
			key: 'lowBalAmt3',
			render: lowBalAmt3 => <span>{ lowBalAmt3 ? `$${ lowBalAmt3.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}` : 'N/A' }</span>
		},
		{
			title: 'Low Balance 4',
			dataIndex: 'lowBalAmt4',
			key: 'lowBalAmt4',
			render: lowBalAmt4 => <span>{ lowBalAmt4 ? `$${ lowBalAmt4.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}` : 'N/A' }</span>
		},
		{
			title: 'Large Credit',
			dataIndex: 'largeCreditAmt',
			key: 'largeCreditAmt',
			render: largeCreditAmt => <span>{ largeCreditAmt ? `$${ largeCreditAmt.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}` : 'N/A' }</span>
		}
	]
}

const BalanceTable = ({ dataSource, view }) => {
	return <Table columns={columns[view]} dataSource={dataSource} pagination={false} />
}

export default BalanceTable;