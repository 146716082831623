import React from 'react';
import { Row, Col } from 'antd';
import DocumentTable from '../components/tables/DocumentTable';

const Documentation = () => {
	return (
		<Row type="flex" justify="center" style={{ marginBottom: '15px' }}>
			<Col xs={18} sm={18} md={18} lg={18} xl={18}>
				<h1>Snapshot Documentation</h1>
				<DocumentTable />
			</Col>
		</Row>
	);
}

export default Documentation;