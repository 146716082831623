import React from 'react';

import AccountTable from './tables/AccountTable';
import ApplicantTable from './tables/ApplicantTable';
import BalanceTable from './tables/BalanceTable';
import ApplicationTable from './tables/ApplicationTable';

const LenderApplication = ({ application, view }) => {
	return (
		<React.Fragment>
			<h3>Applicant Information</h3>
			<ApplicantTable dataSource={[application.user]} view={view} />
			<h3>Balance Thresholds</h3>
			<BalanceTable dataSource={[application]} view={view} />
			<h3>Application Information</h3>
			<ApplicationTable dataSource={[application]} view={view} />
			<h3>Account Information</h3>
			<AccountTable dataSource={application.accounts} view={view} />
		</React.Fragment>
	);
}

const BorrowerApplication = ({ application, view }) => {
	return (
		<React.Fragment>
			<h4>Applicant Information</h4>
			<ApplicantTable dataSource={[application.user]} view={view} />
			<h4>Account Information</h4>
			<AccountTable dataSource={application.accounts} view={view} />
		</React.Fragment>
	);
}

const Application = ({ data, view }) => {
	if (view === 'lender') return <LenderApplication application={data} view={view} />;
	return <BorrowerApplication application={data} view={view} />;
}

export default Application;